import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReduxState } from 'ducks';
import { WebSocketConnectionReceiver } from './WebSocketConnectionReceiver';

export const MapWebSocketConnectionReceiver = () => {
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  return (
    <WebSocketConnectionReceiver targetId={digitalMap?.id} name="MapWebSocketConnectionReceiver" />
  );
};
