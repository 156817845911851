import Script from 'next/script';
import config from 'config';

export const GmoScript = () => (
  <Script
    src={config.gmoScriptUrl}
    onLoad={() => {
      try {
        console.log('Multipayment script loaded');
        if (window.Multipayment) {
          console.log('Multipayment exists:', window.Multipayment);
          window.Multipayment.init(config.gmoShopId || '');
        } else {
          console.error('Multipayment is not defined');
        }
      } catch (error) {
        console.error('Error initializing Multipayment:', error);
      }
    }}
    onError={(e) => {
      console.error('Error loading Multipayment script:', e);
    }}
  />
);
