import * as React from 'react';
import { NextPage } from 'next';
import { Dispatch } from 'redux';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { Layout } from 'components/Layout/Layout';
import { wrapper } from 'ducks';
import {
  fetchSettingsServerSide,
  fetchReservationServerSide,
  fetchProductServerSide,
  fetchProductSummariesServerSide,
} from 'ducks/server';
import { fetchDigitalMap } from 'ducks/universal/digitalMap';
import { withSSR } from 'withSSR';
import { getI18nServerSideProps } from 'i18n';
import { detectLocale } from 'i18n-server';
import { absoluteUrl } from 'lib/util/absoluetUrl';
import { Map } from 'components/Map/Map';
import { CustomerProvider } from 'components/Map/CustomerProvider';
import { fetchDigitalGuidanceStampRally } from 'ducks/universal/digitalGuidanceStampRally';
import { CustomerWebSocketConnectionReceiver } from 'components/Map/WebSocketConnectionReceiver/CustomerWebSocketConnectionReceiver';
import { ReservationWebSocketConnectionReceiver } from 'components/ReservationWebSocketConnectionReceiver/ReservationWebSocketConnectionReceiver';
import { MarketingAutomationDistributor } from 'components/MarketingAutomationV2/MarketingAutomationDistributor';
import { WebPushNotificationReceiver } from 'components/WebPushNotificationReceiver/WebPushNotificationReceiver';
import { TrackingWebSocketConnectionReceiver } from 'components/TrackingWebSocketConnectionReceiver/TrackingWebSocketConnectionReceiver';
import { MapWebSocketConnectionReceiver } from 'components/Map/WebSocketConnectionReceiver/MapWebSocketConnectionReceiver';
import { languageOptionMap } from 'lib/util/i18nHelpers';
import { GmoScript } from 'components/GmoScript/GmoScript';
import { fetchConfigForHostname } from 'lib/util/fetchConfigForHostname';

interface Props {
  apiKey: string;
  host: string;
}

const MapPage: NextPage<Props> = ({ host }) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta property="og:url" content={`${host}${router.asPath}`} />
      </Head>
      <Layout>
        <CustomerProvider>
          <CustomerWebSocketConnectionReceiver />
          <GmoScript />
          <Map />
        </CustomerProvider>
      </Layout>
      <WebPushNotificationReceiver />
      <TrackingWebSocketConnectionReceiver />
      <ReservationWebSocketConnectionReceiver />
      <MapWebSocketConnectionReceiver />
      <MarketingAutomationDistributor allowedDisplayTypes={['POPUP', 'FLOATING_BUTTON']} />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(async ({ query, req, res, store }) => {
  const hostname = req.headers?.host?.split(':')[0] || '';
  const { apiKey, mapId } = await fetchConfigForHostname(hostname);

  if (!mapId) {
    // Return 404 if the mapId is not set
    return { notFound: true };
  }

  const reservationId = query?.r as string;
  const productId = query?.p as string;

  const dispatch: Dispatch<any> = store.dispatch;

  let i18nProps = {};

  let locale = await detectLocale(req, res);

  // Fetch settings using detected language
  await dispatch(fetchSettingsServerSide(apiKey, '', locale, `https://${hostname}`));

  // Verify that the detected language is in the supplier's supported languages. If it isn't,
  // default to the first supported language and refetch settings.
  const langOptions = store
    .getState()
    .server.settings.all.supported_languages?.map((lang) => languageOptionMap[lang]);

  if (langOptions.length > 0 && !langOptions.some((langOpt) => langOpt.iso === locale)) {
    locale = langOptions[0].iso;
    await dispatch(fetchSettingsServerSide(apiKey, '', locale, `https://${hostname}`));
  }

  const promises = [dispatch(fetchDigitalMap(apiKey, mapId, locale, reservationId))];
  if (reservationId) {
    promises.push(dispatch(fetchReservationServerSide(apiKey, reservationId, locale)));
  }
  if (productId) {
    promises.push(dispatch(fetchProductServerSide(apiKey, productId, locale)));
  }
  promises.push(dispatch(fetchProductSummariesServerSide(apiKey, locale, true)));

  await Promise.all(promises);

  const map = store.getState().universal.digitalMap.map;
  if (map?.stamp_rally_id) {
    await dispatch(fetchDigitalGuidanceStampRally(apiKey, map.stamp_rally_id, locale));
  }

  i18nProps = await getI18nServerSideProps(locale);

  (req as any).locale = locale;

  return {
    props: {
      apiKey,
      host: absoluteUrl(req),
      mapId,
      ...i18nProps,
    },
  };
});

export default withSSR()(MapPage);
