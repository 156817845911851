import * as React from 'react';
import { useDispatch } from 'react-redux';

import config from 'config';
import { addNotification } from 'ducks/client/notification';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useVisitorIdentifier, VisitorIdentifier } from 'hooks/useVisitorIdentifier';

/**
 * Establishes a WebSocket connection to receive ws notifications for non-reservation via tracking id.
 */
export const TrackingWebSocketConnectionReceiver = () => {
  const dispatch = useDispatch();
  const visitorIdentifier = useVisitorIdentifier();
  const stringifiedVisitorIdentifier = JSON.stringify(visitorIdentifier);

  const { apiKey } = React.useContext(ApiKeyContext);

  React.useEffect(() => {
    const parsedVisitorIdentifier = JSON.parse(stringifiedVisitorIdentifier) as VisitorIdentifier;

    if (!apiKey) {
      return;
    }

    if (!parsedVisitorIdentifier.trackingId) {
      return;
    }

    try {
      const webSocketEndpointUrl = config.webSocketEndpointUrl;

      if (!webSocketEndpointUrl) {
        throw new Error('web socket endpoint URL not present in configs');
      }

      const trackingId = parsedVisitorIdentifier.trackingId;
      const ws = new WebSocket(`${webSocketEndpointUrl}?apiKey=${apiKey}&targetId=${trackingId}`);

      ws.onopen = () => {
        console.log('Websocket: opened');
      };

      ws.onclose = () => {
        console.log('Websocket: closed');
      };

      ws.onmessage = (event) => {
        if (typeof event.data === 'string') {
          const notification: any = JSON.parse(event.data);
          dispatch(addNotification(notification));
        }
      };

      return () => ws.close();
    } catch (err) {
      console.log('err', err);
    }
  }, [apiKey, dispatch, stringifiedVisitorIdentifier]);

  return null;
};
